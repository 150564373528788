import { useContext, createContext, useState, useCallback, SetStateAction } from 'react';

import { I18nProvider } from './I18nProvider';

import { CompactSelection, DataGridColumn, DataRecord, GridSelection } from '../types/grid-types';

export type GlideContextType = {
  gridData: DataRecord[];
  displayedGridData: DataRecord[];
  gridColumns: DataGridColumn[];
  currentPageSize: number;
  selection: GridSelection;
  lastRefreshTimestamp: number | undefined;
  frozenColumn: number;
  isGridLoading: boolean;
  setGridData: (gridData: DataRecord[]) => void;
  setDisplayedGridData: (gridData: DataRecord[]) => void;
  setGridColumns: (gridColumns: DataGridColumn[] | SetStateAction<DataGridColumn[]>) => void;
  setCurrentPageSize: (pageSize: number) => void;
  setSelection: (selection: GridSelection | SetStateAction<GridSelection>) => void;
  refreshDataGrid: () => void;
  setFrozenColumn: (columnIndex: number | SetStateAction<number>) => void;
  setIsGridLoading: (isLoading: boolean) => void;
};

const GlideContext = createContext<GlideContextType>({} as GlideContextType);
export const useGlideContext = () => useContext(GlideContext);

export const GlideContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [gridData, setGridData] = useState<DataRecord[]>([]);
  const [displayedGridData, setDisplayedGridData] = useState<DataRecord[]>([]);
  const [gridColumns, setGridColumns] = useState<DataGridColumn[]>([]);
  const [currentPageSize, setCurrentPageSize] = useState<number>(0);
  const [isGridLoading, setIsGridLoading] = useState<boolean>(false);
  const [selection, setSelection] = useState<GridSelection>({
    columns: CompactSelection.empty(),
    rows: CompactSelection.empty(),
  });
  const [lastRefreshTimestamp, setLastRefreshTimestamp] = useState<number | undefined>();
  const [frozenColumn, setFrozenColumn] = useState<number>(0);

  const refreshDataGrid = useCallback(() => {
    // Trigger a refresh on the data grid
    setLastRefreshTimestamp(Date.now());
  }, []);

  return (
    <I18nProvider>
      <GlideContext.Provider
        value={{
          gridData,
          displayedGridData,
          gridColumns,
          currentPageSize,
          selection,
          lastRefreshTimestamp,
          isGridLoading,
          frozenColumn,
          setGridData,
          setDisplayedGridData,
          setGridColumns,
          setCurrentPageSize,
          setSelection,
          refreshDataGrid,
          setFrozenColumn,
          setIsGridLoading,
        }}
      >
        {children}
      </GlideContext.Provider>
    </I18nProvider>
  );
};
