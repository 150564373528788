import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { faRaindrops } from '@fortawesome/pro-regular-svg-icons/faRaindrops';
import { faSnowflake } from '@fortawesome/pro-regular-svg-icons/faSnowflake';
import { EditableGridCell, GridCell, GridCellKind, GridColumn, Item, Rectangle } from '@glideapps/glide-data-grid';
import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLayer } from 'react-laag';
import { PlacementType } from 'react-laag/dist/PlacementType';

import { Icon } from 'quotient';

import { DropdownCell } from '../components/DropdownCell';
import { useGlideContext } from '../context/GlideContext';
import { getCellValue } from '../data-grid-utils';
import { NumberFormatter } from '../formatters/NumberFormatter';
import {
  badgeMapType,
  CellContentConfigType,
  DataGridColumn,
  DataGridDropdownConfig,
  DataRecord,
  ERROR_VALUE_TO_DISPLAY_VALUE,
  GridCellUpdate,
  HeaderContextMenuConfig,
  MenuGroupOption,
  RowDataTypes,
} from '../types/grid-types';
import { BOOLEAN_OPTIONS } from '../utils/constants';

export function useGetDataGridEventHandlers() {
  const { gridColumns, isGridLoading, displayedGridData, setDisplayedGridData, setGridColumns } = useGlideContext();

  const getCellContent = useCallback(
    (
      cell: Item,
      dropdownOptionsMapper?: (
        data: Record<string, RowDataTypes>,
        columnId: string,
      ) => { label: string; value: string }[],
      dropdownConfig?: DataGridDropdownConfig,
      cellContentConfig?: CellContentConfigType,
      dateFormatter?: (dateInput: string | Date | undefined, format: string) => string,
      badgeMap?: badgeMapType,
      // eslint-disable-next-line sonarjs/cognitive-complexity
    ): GridCell => {
      let cellContent: GridCell;

      const [colIndex, rowIndex] = cell;
      const dataRow = displayedGridData && displayedGridData[rowIndex] && displayedGridData[rowIndex].data;
      const columnId = gridColumns[colIndex].id;
      const cellData = dataRow?.[columnId];

      const columnDefinition = gridColumns[colIndex];
      const isColumnEditable = !isGridLoading && (columnDefinition?.editable || false);

      if (columnDefinition.type === 'number') {
        const cellDataFormatted = (cellData ?? '').toString();
        let displayData = '';
        let effectiveData = cellData;
        if (typeof cellData === 'string' && cellData in ERROR_VALUE_TO_DISPLAY_VALUE) {
          displayData = ERROR_VALUE_TO_DISPLAY_VALUE[cellData];
        } else {
          displayData = cellDataFormatted;
          if (columnDefinition.format) {
            const { formattedValueWithPrefixSuffix, formattedValue } = NumberFormatter.formatForDisplay(
              cellDataFormatted,
              columnDefinition.format,
            );
            displayData = formattedValueWithPrefixSuffix;
            effectiveData = formattedValue || effectiveData;
          }
        }

        cellContent = {
          kind: GridCellKind.Number,
          allowOverlay: isColumnEditable,
          displayData,
          data: effectiveData as number,
        };
      } else if (columnDefinition.type === 'boolean') {
        const currentOption = BOOLEAN_OPTIONS.find((option) => {
          return option.value === cellData?.toString().toUpperCase();
        });

        cellContent = {
          kind: GridCellKind.Custom,
          allowOverlay: isColumnEditable,
          copyData: currentOption?.value as string,
          readonly: false,
          data: {
            kind: 'dropdown-cell',
            allowedValues: BOOLEAN_OPTIONS,
            value: currentOption?.value,
            label: currentOption?.label,
            rowData: dataRow,
            columnDefinition,
          },
        };
      } else if (columnDefinition.type === 'dropdown' && (dropdownOptionsMapper || dropdownConfig?.asyncOptions)) {
        let dropdownOptions;
        let currentOption;
        if (dropdownOptionsMapper) {
          dropdownOptions = dropdownOptionsMapper(dataRow, columnDefinition?.id as string);
          currentOption = dropdownOptions.find((option) => {
            return option.value === cellData;
          });
        } else {
          currentOption = { label: cellData, value: cellData };
        }

        cellContent = {
          kind: GridCellKind.Custom,
          allowOverlay: isColumnEditable,
          copyData: currentOption?.value as string,
          readonly: false,
          data: {
            kind: 'dropdown-cell',
            allowedValues: dropdownOptions || [],
            value: currentOption?.value,
            label: currentOption?.label,
            asyncOptions: dropdownConfig?.asyncOptions,
            rowData: dataRow,
            columnDefinition,
          },
        };
      } else if (columnDefinition.type === 'date') {
        const cellDataFormatted = (cellData ?? '').toString();
        let displayData = '';
        try {
          if (cellDataFormatted === '') {
            displayData = '';
          } else if (typeof cellData === 'string' && cellData in ERROR_VALUE_TO_DISPLAY_VALUE) {
            displayData = ERROR_VALUE_TO_DISPLAY_VALUE[cellData];
          } else if (typeof dateFormatter === 'function') {
            displayData = dateFormatter(cellDataFormatted, 'dateShort');
          } else {
            displayData = new Intl.DateTimeFormat().format(new Date(cellDataFormatted));
          }
        } catch (error) {
          console.error('Error formatting date', error);
        }

        cellContent = {
          kind: GridCellKind.Text,
          allowOverlay: isColumnEditable,
          displayData,
          data: cellData as string,
        };
      } else if (columnDefinition.type === 'badge' && badgeMap) {
        cellContent = {
          kind: GridCellKind.Custom,
          allowOverlay: false,
          readonly: true,
          data: {
            kind: 'badge-cell',
            value: cellData,
            badgeMap,
          },
          copyData: cellData as string,
        };
      } else {
        // Default to a text cell editor
        cellContent = {
          kind: GridCellKind.Text,
          allowOverlay: isColumnEditable,
          displayData: cellData?.toString() ?? '',
          data: cellData?.toString() ?? '',
        };
      }

      // post processing of cell content
      if (typeof cellContentConfig?.postGetCellContent === 'function') {
        cellContent = cellContentConfig.postGetCellContent({
          cell,
          cellData,
          columnDefinition,
          gridCellContent: cellContent,
        });
      }

      return cellContent;
    },
    [displayedGridData, gridColumns, isGridLoading],
  );

  const updateCellData = useCallback(
    (gridDataCopy: DataRecord[], cell: Item, newCellValue: EditableGridCell) => {
      const [colIndex, rowIndex] = cell;
      const columnDefinition = gridColumns[colIndex];
      const columnId = gridColumns[colIndex].id;

      // Get the raw value of the cell
      const rawCellValue = getCellValue(newCellValue, columnDefinition);

      let newCellData;
      let finalCellValue;
      // For dropdowns we just use the option value and don't do any additional formatting.
      if (newCellValue.kind === 'custom') {
        newCellData = (newCellValue as DropdownCell).data;
        finalCellValue = (newCellValue as DropdownCell).data.value;
      } else {
        newCellData = rawCellValue; // In the non-dropdown case, the data just points to the actual primitive data
        finalCellValue = rawCellValue;
      }
      const dataRow = (gridDataCopy as any)[rowIndex].data;
      dataRow[columnId] = finalCellValue;

      return newCellData as EditableGridCell['data'];
    },
    [gridColumns],
  );

  const getCellData = (dataRecords: DataRecord[], columns: DataGridColumn[], cell: Item) => {
    const [colIndex, rowIndex] = cell;
    const column = columns[colIndex];
    const rowId = dataRecords[rowIndex].id;
    const currentCellValue = dataRecords[rowIndex]?.data[column.id];
    return {
      column,
      rowId,
      value: currentCellValue,
    };
  };

  const onCellEdited = useCallback(
    (
      cell: Item,
      newCellValue: EditableGridCell,
      onCellDataPushed?: (
        cell: Item,
        newCellData: EditableGridCell,
        previousCellData: { column: DataGridColumn; rowId: string; value: RowDataTypes | undefined },
      ) => void,
    ) => {
      const gridDataCopy = cloneDeep(displayedGridData);
      const previousCellData = getCellData(gridDataCopy, gridColumns, cell);

      updateCellData(gridDataCopy, cell, newCellValue);
      setDisplayedGridData(gridDataCopy);
      if (onCellDataPushed) {
        onCellDataPushed(cell, newCellValue, previousCellData);
      }
    },
    [displayedGridData, gridColumns, updateCellData, setDisplayedGridData],
  );

  const onCellsEdited = useCallback(
    (
      newValues: readonly { location: Item; value: EditableGridCell }[],
      onCellsDataPushed: (cellUpdates: { cell: Item; newCellValue: EditableGridCell }[]) => void,
    ): boolean | void => {
      const gridDataCopy = cloneDeep(displayedGridData);
      const cellUpdates: GridCellUpdate[] = [];

      newValues.forEach((value) => {
        const newCellData = updateCellData(gridDataCopy, value.location, value.value);

        const cellValueCopy = { ...value.value };
        const cellValueCopyUpdated = { ...cellValueCopy, data: newCellData } as EditableGridCell;

        cellUpdates.push({
          cell: value.location,
          newCellValue: cellValueCopyUpdated,
        });
      });
      setDisplayedGridData(gridDataCopy);
      onCellsDataPushed(cellUpdates);
      return true;
    },
    [displayedGridData, setDisplayedGridData, updateCellData],
  );

  const onColumnResize = useCallback(
    (column: GridColumn, newSize: number) => {
      setGridColumns((prevColumns: DataGridColumn[]) => {
        const index = prevColumns.findIndex((ci) => ci.id === column.id);
        const newColumnsArray = [...prevColumns];

        // Update the associated column with the new width
        newColumnsArray.splice(index, 1, {
          ...prevColumns[index],
          width: newSize,
        });
        return newColumnsArray;
      });
    },
    [setGridColumns],
  );

  const onRowAppended = useCallback(() => {}, []);

  return { getCellContent, onCellEdited, onCellsEdited, onColumnResize, onRowAppended };
}

const useFrozenColumnItems = (
  headerContextMenuConfig: HeaderContextMenuConfig = {},
  contextMenuPosition?: { col: number; bounds: Rectangle },
) => {
  const { frozenColumn, setFrozenColumn, selection } = useGlideContext();
  const { t } = useTranslation();
  const isBulkSelecting = selection.columns.length > 1;
  return useMemo(
    () =>
      headerContextMenuConfig?.frozenColumnConfig?.enabled === true
        ? [
            {
              title: t('header_context.freeze_column', { count: selection.columns.length }),
              action: () => {
                const newFrozenColumn = isBulkSelecting ? selection.columns.last() : contextMenuPosition?.col;
                if (newFrozenColumn !== undefined) {
                  setFrozenColumn(newFrozenColumn + 1);
                  if (headerContextMenuConfig.frozenColumnConfig?.onFreezeColumn) {
                    headerContextMenuConfig.frozenColumnConfig.onFreezeColumn(newFrozenColumn + 1);
                  }
                }
              },
              grouping: 'freeze' as MenuGroupOption,
              icon: <Icon icon={faSnowflake} />,
            },
            {
              title: t('header_context.unfreeze_columns'),
              action: () => {
                setFrozenColumn(0);
                if (headerContextMenuConfig.frozenColumnConfig?.onFreezeColumn) {
                  headerContextMenuConfig.frozenColumnConfig.onFreezeColumn(0);
                }
              },
              grouping: 'freeze' as MenuGroupOption,
              icon: <Icon icon={faRaindrops} />,
              disabled: frozenColumn === 0,
            },
          ]
        : [],
    [
      t,
      headerContextMenuConfig.frozenColumnConfig,
      isBulkSelecting,
      frozenColumn,
      selection.columns,
      contextMenuPosition?.col,
      setFrozenColumn,
    ],
  );
};

const useDeleteColumns = (
  headerContextMenuConfig: HeaderContextMenuConfig = {},
  contextMenuPosition?: { col: number; bounds: Rectangle },
) => {
  const { selection } = useGlideContext();
  const { t } = useTranslation();
  const isDeleteEnabled = !!headerContextMenuConfig.deleteColumnConfig?.enabled;
  return useMemo(
    () =>
      isDeleteEnabled
        ? [
            {
              title: t('header_context.delete_column', { count: selection.columns.length }),
              action: () => {
                if (
                  contextMenuPosition?.col !== undefined &&
                  headerContextMenuConfig.deleteColumnConfig?.onDeleteColumn
                ) {
                  const selectedColumnIndexes = selection.columns.toArray();
                  headerContextMenuConfig.deleteColumnConfig.onDeleteColumn(selectedColumnIndexes);
                }
              },
              grouping: 'destructive' as MenuGroupOption,
              icon: <Icon icon={faTrashCan} />,
            },
          ]
        : [],
    [isDeleteEnabled, t, selection.columns, contextMenuPosition?.col, headerContextMenuConfig.deleteColumnConfig],
  );
};

export function useGetHeaderContextMenuItems(
  headerContextMenuConfig: HeaderContextMenuConfig = {},
  contextMenuPosition?: { col: number; bounds: Rectangle },
) {
  const frozenColumnItems = useFrozenColumnItems(headerContextMenuConfig, contextMenuPosition);
  const deleteColumnItems = useDeleteColumns(headerContextMenuConfig, contextMenuPosition);
  const headerContextMenuItems = useMemo(
    () => [...deleteColumnItems, ...frozenColumnItems, ...(headerContextMenuConfig?.customItems ?? [])],
    [frozenColumnItems, deleteColumnItems, headerContextMenuConfig?.customItems],
  );
  return { headerContextMenuItems };
}

export function useGetLayerConfiguration(
  isOpen: boolean,
  contextMenuPosition: { col: number; bounds: Rectangle } | undefined,
  contextMenuPlacement: PlacementType,
  setContextMenuPosition: Function,
) {
  const { layerProps: contextMenulayerProps } = useLayer({
    isOpen,
    auto: true,
    placement: contextMenuPlacement,
    triggerOffset: 2,
    onOutsideClick: () => {
      setContextMenuPosition(undefined);
    },
    trigger: {
      getBounds: () => ({
        left: contextMenuPosition?.bounds.x ?? 0,
        top: contextMenuPosition?.bounds.y ?? 0,
        width: contextMenuPosition?.bounds.width ?? 0,
        height: contextMenuPosition?.bounds.height ?? 0,
        right: (contextMenuPosition?.bounds.x ?? 0) + (contextMenuPosition?.bounds.width ?? 0),
        bottom: (contextMenuPosition?.bounds.y ?? 0) + (contextMenuPosition?.bounds.height ?? 0),
      }),
    },
  });

  return contextMenulayerProps;
}
