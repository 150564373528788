import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useEffect, useState } from 'react';

import { getCellValue, isDropdownCell } from '../data-grid-utils';
import { DataGridColumn, DataRecord, EditableGridCell, GridCellUpdate, Item } from '../types/grid-types';
import { GridChangeEvent, GridChangeEventType } from '../types/record-hook-types';

export const gridCellUpdateToGridChangeEvent = (cellUpdate: GridCellUpdate): GridChangeEvent => {
  return {
    id: crypto.randomUUID(),
    created_at: new Date().getTime(),
    type: GridChangeEventType.CELL_EDITED,
    cell: cellUpdate.cell,
    newCellValue: cellUpdate.newCellValue,
  };
};

export const useManualCommitMode = (
  originalRecords: DataRecord[],
  gridColumns: DataGridColumn[],
  onCellsDataPushedParent: (cellUpdates: GridChangeEvent[]) => Promise<boolean>, // wether push succeeded or not
  onCellsDataCommitedParent: (
    committedChanges: GridChangeEvent[],
    triggerPush: (changesToPush?: GridChangeEvent[]) => Promise<void>,
    rejectCommits: () => void,
  ) => void,
) => {
  const [displayRecords, setDisplayRecords] = useState<DataRecord[]>(originalRecords);
  const recordCount = displayRecords.length;

  const [commitBuffer, setCommitBuffer] = useState<GridChangeEvent[]>([]);

  // Important Note: Updating the records passed into this hook will reset the commit buffer
  // as well as the currently displayed records. Generally pass in new records only if the source of truth
  // has changed or if you want to forcibly overwrite the current records.
  useEffect(() => {
    setDisplayRecords(originalRecords);
    setCommitBuffer([]);
  }, [originalRecords]);

  const pushToCommitBuffer = useCallback((newChanges: GridCellUpdate[], existingBuffer: GridChangeEvent[]) => {
    const newBuffer = [...existingBuffer, ...newChanges.map(gridCellUpdateToGridChangeEvent)];
    setCommitBuffer(newBuffer);
    return newBuffer;
  }, []);

  const updateCellData = useCallback(
    (gridDataCopy: DataRecord[], cell: Item, newCellValue: EditableGridCell) => {
      const [colIndex, rowIndex] = cell;
      const columnDefinition = gridColumns[colIndex];
      const columnId = columnDefinition.id;

      // Get the raw value of the cell
      const rawCellValue = getCellValue(newCellValue, columnDefinition);

      let newCellData;
      let finalCellValue;
      // For dropdowns we just use the option value and don't do any additional formatting.
      if (isDropdownCell(newCellValue)) {
        newCellData = newCellValue.data;
        finalCellValue = newCellValue.data.value;
      } else {
        newCellData = rawCellValue; // In the non-dropdown case, the data just points to the actual primitive data
        finalCellValue = rawCellValue;
      }
      const dataRow = gridDataCopy[rowIndex].data;
      dataRow[columnId] = finalCellValue;

      return newCellData;
    },
    [gridColumns],
  );

  const getTriggerPushCallback = useCallback(
    (
      latestCommitBuffer: GridChangeEvent[],
      onCellsDataPushed?: (cellUpdates: GridChangeEvent[]) => Promise<boolean>,
    ) => {
      return async (changesToPush?: GridChangeEvent[]) => {
        // We allow the consumer component to 'overwrite' the commits by specifying 'changesToPush'. Otherwise we
        // just fallback to whatever's been accumulated in the commit buffer.
        const cellUpdates: GridChangeEvent[] = changesToPush || latestCommitBuffer || [];

        if (cellUpdates.length > 0 && onCellsDataPushed) {
          // notify parent component of the changes, assumes all changes either apply or fail as a unit
          const success = await onCellsDataPushed(cellUpdates);

          if (success) {
            // Clear the buffer after the push
            setCommitBuffer([]);
          }
        }
      };
    },
    [setCommitBuffer],
  );

  const getRejectCommitsCallback = useCallback(
    (latestCommitBuffer: GridChangeEvent[]) => {
      return () => {
        if (latestCommitBuffer.length > 0) {
          // Reset the commit buffer
          setCommitBuffer([]);

          // Reset gridrecords back to the original state
          setDisplayRecords(originalRecords);
        }
      };
    },
    [originalRecords],
  );

  const onCellsEdited = useCallback(
    (newValues: readonly GridCellUpdate[]): boolean | void => {
      const gridDataCopy = cloneDeep(displayRecords);
      const newCommits: GridCellUpdate[] = [];

      newValues.forEach((value) => {
        const newCellData = updateCellData(gridDataCopy, value.cell, value.newCellValue);

        const cellValueCopy = { ...value.newCellValue };
        const cellValueCopyUpdated = { ...cellValueCopy, data: newCellData } as EditableGridCell;

        // Push the change onto the commit buffer
        newCommits.push({
          cell: value.cell,
          newCellValue: cellValueCopyUpdated,
        });
      });
      // update displayed records
      setDisplayRecords(gridDataCopy);

      // If there were changes commited, update the commit buffer and trigger the commit callback to the parent
      const latestBuffer = pushToCommitBuffer(newCommits, commitBuffer);
      const triggerPush = getTriggerPushCallback(latestBuffer, onCellsDataPushedParent);
      const rejectCommits = getRejectCommitsCallback(latestBuffer);
      onCellsDataCommitedParent(latestBuffer, triggerPush, rejectCommits);
      return true;
    },
    [
      displayRecords,
      commitBuffer,
      onCellsDataCommitedParent,
      updateCellData,
      pushToCommitBuffer,
      getTriggerPushCallback,
      onCellsDataPushedParent,
      getRejectCommitsCallback,
    ],
  );

  return {
    displayRecords,
    recordCount,
    onCellsEdited,
  };
};

export const useAutoCommitMode = (
  originalRecords: DataRecord[],
  gridColumns: DataGridColumn[],
  onCellDataPushed: (cellUpdates: GridChangeEvent[]) => Promise<boolean>, // wether push succeeded or not
) => {
  const onCellsDataCommited = useCallback(
    (
      _committedChanges: GridChangeEvent[],
      triggerPush: (changesToPush?: GridChangeEvent[]) => Promise<void>,
      _rejectCommits: () => void,
    ) => {
      // attempt to trigger push immediately
      triggerPush();
    },
    [],
  );
  const { displayRecords, onCellsEdited } = useManualCommitMode(
    originalRecords,
    gridColumns,
    onCellDataPushed,
    onCellsDataCommited,
  );
  return { displayRecords, onCellsEdited };
};
