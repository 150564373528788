import { faQuestionCircle, faFileExport, faPlus } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Flex, Search, Spacer, Icon, Tooltip } from 'quotient';

import { FilterIcon } from './FilterIcon';

import { GridMenuConfig } from '../types/grid-types';

type Props = {
  config?: GridMenuConfig;
};
const GridMenu: React.VFC<Props> = ({ config }: Props) => {
  const { t } = useTranslation();
  const leftSideMenuItems = [];
  const rightSideMenuItems = [];

  if (config?.addColumnConfig) {
    leftSideMenuItems.push(
      <Button
        data-testid="grid-add-column-button"
        key="add_column_button"
        leftIcon={faPlus}
        unmask
        variant="ghost"
        onClick={config.addColumnConfig.callback}
      >
        {t('actions.add_column')}
      </Button>,
    );
  }

  if (config?.exportConfig) {
    leftSideMenuItems.push(
      <Button
        key={crypto.randomUUID()}
        leftIcon={faFileExport}
        unmask
        variant="ghost"
        onClick={config.exportConfig.callback}
      >
        {t('actions.export_as_csv')}
      </Button>,
    );
  }

  if (config?.searchConfig) {
    const { onSearch, searchHelpText } = config.searchConfig;
    leftSideMenuItems.push(
      <Flex alignItems="center" key="records-search-box" width="300px">
        <Search placeholder="Search keyword" onSearch={onSearch} />
        {searchHelpText && (
          <Tooltip hasArrow label={searchHelpText} placement="top">
            <Icon icon={faQuestionCircle} ml={1} />
          </Tooltip>
        )}
      </Flex>,
    );
  }

  if (config?.getCustomConfig) {
    const configs = config.getCustomConfig();
    configs.forEach((c) => {
      leftSideMenuItems.push(c.menuItem);
    });
  }

  // TODO: merge this into customConfig above
  if (config?.filterConfig) {
    rightSideMenuItems.push(
      <FilterIcon
        buttonLabel={config.filterConfig?.buttonLabel}
        callback={config.filterConfig.callback}
        filterCount={config.filterConfig.filterCount}
        key="filter-button"
      />,
    );
  }

  if (leftSideMenuItems.length === 0 && rightSideMenuItems.length === 0) {
    return <></>;
  }

  return (
    <Flex
      bg="primarySecondary.50"
      borderColor="primaryNeutral.400"
      borderTopWidth="px"
      justifyContent="space-between"
      p={2}
      w="full"
    >
      {leftSideMenuItems.length > 0 && (
        <Flex data-testid="data-grid-menu" key="gridmenu-left">
          {leftSideMenuItems}
        </Flex>
      )}
      {leftSideMenuItems.length === 0 && <Spacer />}
      {rightSideMenuItems.length > 0 && <Flex key="gridmenu-right">{rightSideMenuItems}</Flex>}
      {rightSideMenuItems.length === 0 && <Spacer />}
    </Flex>
  );
};

export default GridMenu;
