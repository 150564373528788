import '@glideapps/glide-data-grid/dist/index.css';
import {
  DataEditor,
  GridColumn,
  Rectangle,
  CellArray,
  GetCellsThunk,
  Item,
  EditableGridCell,
  HeaderClickedEventArgs,
  Highlight,
  CellClickedEventArgs,
  DataEditorRef,
  GetRowThemeCallback,
  DrawCellCallback,
  GridSelection,
  CompactSelection,
} from '@glideapps/glide-data-grid';
import { RowMarkerOptions } from '@glideapps/glide-data-grid/dist/dts/data-editor/data-editor';
import cloneDeep from 'lodash/cloneDeep';
import { useEffect, useState, useCallback, forwardRef } from 'react';
import { IBounds } from 'react-laag';
import { PlacementType } from 'react-laag/dist/PlacementType';

import { Box, Button, Center, Flex, Spinner, Text, useToken } from 'quotient';

import BadgeCellRenderer from './components/BadgeCell';
import { ContextMenu } from './components/ContextMenu/ContextMenu';
import { MenuItem } from './components/ContextMenu/types';
import DropdownCellRenderer from './components/DropdownCell';
import GridMenu from './components/GridMenu';
import { GridPopover } from './components/GridPopover';
import { useGlideContext } from './context/GlideContext';
import { coercePasteValue, onCellClickHandler, onPasteWithSingleRowTiling } from './data-grid-utils';
import {
  useGetDataGridEventHandlers,
  useGetHeaderContextMenuItems,
  useGetLayerConfiguration,
} from './hooks/data-grid-hooks';
import {
  GridPopoverConfig,
  GridPopoverContext,
  DataGridColumn,
  DataGridDropdownConfig,
  DataGridPaginationConfig,
  DataRecord,
  GridMenuConfig,
  RowData,
  PreviousCellData,
  CellContentConfigType,
  GridCellUpdate,
  HeaderContextMenuConfig,
  badgeMapType,
  GridLoadingConfig,
} from './types/grid-types';
import { DISABLED_KEY_BINDINGS } from './utils/constants';

export type GlideProps = {
  columns: DataGridColumn[];
  data: DataRecord[];
  className?: string;
  enablePagination?: boolean;
  paginationConfig?: DataGridPaginationConfig;
  width?: string | number;
  height?: string | number;
  rowHeight?: number;
  headerHeight?: number;
  onPaste?: boolean | ((target: Item, values: readonly (readonly string[])[]) => boolean);
  rowMarkers?: RowMarkerOptions;
  rightElement?: React.ReactNode;
  initialSize?: [width: number, height: number];
  highlightRegions?: Highlight[];
  getRowThemeOverride?: GetRowThemeCallback;
  /** This method is used to render the options of a cell if the associated column is of type 'dropdown'. This callback
   * provides the rowData as well as the column id of the associated cell.
   */
  dropdownOptionsMapper?: (rowData: RowData, columnId: string) => { label: string; value: string }[];
  badgeMap?: badgeMapType;
  dropdownConfig?: DataGridDropdownConfig;
  rightElementProps?:
    | {
        readonly sticky?: boolean | undefined;
        readonly fill?: boolean | undefined;
      }
    | undefined;
  trailingRowOptions?:
    | {
        readonly tint?: boolean | undefined;
        readonly hint?: string | undefined;
        readonly sticky?: boolean | undefined;
        readonly addIcon?: string | undefined;
        readonly targetColumn?: number | GridColumn | undefined;
      }
    | undefined;
  getCellsForSelection?:
    | true
    | ((selection: Rectangle, abortSignal: AbortSignal) => GetCellsThunk | CellArray)
    | undefined;
  headerContextMenuConfig?: HeaderContextMenuConfig;
  cellContextMenuItems?: MenuItem[];
  menuConfig?: GridMenuConfig;
  gridPopoverConfig?: GridPopoverConfig;
  gridPopoverContext?: GridPopoverContext;
  trackCellClickPositions?: boolean;
  showVerticalBorders?: boolean;
  showHorizontalBorders?: boolean;
  initialFrozenColumn?: number;
  loadingConfig?: GridLoadingConfig;
  onRowAppended?: () => void;
  onCellDataPushed?: (cell: Item, newCellValue: EditableGridCell, previousCellData: PreviousCellData) => void;
  onCellsDataPushed?: (cellUpdates: GridCellUpdate[]) => void;
  onCellClicked?: (cell: Item, bounds: IBounds) => void;
  onCellContextMenu?: (cell: Item, event: CellClickedEventArgs) => void;
  onHeaderClicked?: (colIndex: number, event: HeaderClickedEventArgs) => void;
  onHeaderMenuClick?: (col: number, screenPosition: Rectangle) => void;
  drawCell?: DrawCellCallback;
  cellContentConfig?: CellContentConfigType;
  canClickHeaderMenu?: (col: number, screenPosition: Rectangle) => boolean;
  dateFormatter?: (dateInput: string | Date | undefined, format: string) => string;
  onAfterColumnMove?: (startIndex: number, endIndex: number) => void;
  onBeforeColumnMove?: (startIndex: number, endIndex: number) => boolean;
  onVisibleRegionChanged?: (
    range: Rectangle,
    tx: number,
    ty: number,
    extras: {
      /** The selected item if visible */
      selected?: Item;
      /**
       * All visible freeze regions
       */
      freezeRegions?: readonly Rectangle[];
    },
  ) => void;
};

// The list of configurations that Glide applies once at mount time
type InitialConfiguration = {
  initialFrozenColumn: boolean;
};

export const GlideGrid = forwardRef<DataEditorRef, GlideProps>(
  (
    {
      columns,
      data,
      className,
      enablePagination,
      paginationConfig,
      width,
      height,
      rowHeight,
      headerHeight,
      rowMarkers,
      onPaste,
      rightElement,
      rightElementProps,
      trailingRowOptions,
      getCellsForSelection,
      dropdownConfig,
      headerContextMenuConfig,
      cellContextMenuItems,
      initialSize,
      gridPopoverConfig,
      gridPopoverContext,
      menuConfig,
      highlightRegions,
      loadingConfig,
      getRowThemeOverride,
      trackCellClickPositions,
      showHorizontalBorders,
      showVerticalBorders,
      dropdownOptionsMapper,
      onRowAppended,
      onCellDataPushed,
      onCellsDataPushed,
      onCellClicked,
      onCellContextMenu,
      onHeaderClicked,
      onHeaderMenuClick,
      drawCell,
      initialFrozenColumn,
      cellContentConfig,
      canClickHeaderMenu,
      dateFormatter,
      onAfterColumnMove,
      onBeforeColumnMove,
      badgeMap,
      onVisibleRegionChanged,
    },
    ref,
  ) => {
    const [page, setPage] = useState<number>(1);
    const [numOfPages, setNumOfPages] = useState<number>(1);

    const [headerContextMenuPosition, setHeaderContextMenuPosition] = useState<{ col: number; bounds: Rectangle }>();
    const [cellContextMenuPosition, setCellContextMenuPosition] = useState<{ col: number; bounds: Rectangle }>();
    const [contextMenuPlacement, setContextMenuPlacement] = useState<PlacementType>('bottom-end');
    const [cellPopoverContext, setCellPopoverContext] = useState<{ cell: Item; bounds: IBounds }>();
    const [configApplied, setConfigApplied] = useState<InitialConfiguration>({ initialFrozenColumn: false });

    const {
      gridData,
      gridColumns,
      currentPageSize,
      selection,
      lastRefreshTimestamp,
      frozenColumn,
      setGridData,
      setDisplayedGridData,
      setCurrentPageSize,
      setGridColumns,
      setSelection,
      setFrozenColumn,
      setIsGridLoading,
    } = useGlideContext();

    const { getCellContent, onCellEdited, onCellsEdited, onColumnResize } = useGetDataGridEventHandlers();
    const { headerContextMenuItems } = useGetHeaderContextMenuItems(headerContextMenuConfig, headerContextMenuPosition);

    const _getCellContent = useCallback(
      (cell: Item) =>
        getCellContent(cell, dropdownOptionsMapper, dropdownConfig, cellContentConfig, dateFormatter, badgeMap),
      [cellContentConfig, dropdownConfig, dropdownOptionsMapper, getCellContent, dateFormatter, badgeMap],
    );

    const refreshDisplayData = useCallback(
      (currentGridData) => {
        const gridDataCopy = cloneDeep(currentGridData);
        if (!enablePagination || !paginationConfig?.pageSize) {
          setDisplayedGridData(gridDataCopy);
          setCurrentPageSize(gridDataCopy.length);
          setNumOfPages((enablePagination && paginationConfig?.totalPages) || 1);
          return;
        }

        const pagesNum = Math.ceil(gridDataCopy.length / paginationConfig?.pageSize);
        setNumOfPages(pagesNum);

        const indexStart = (page - 1) * paginationConfig?.pageSize;
        const dataSlice = gridDataCopy.slice(indexStart, indexStart + paginationConfig?.pageSize);
        setCurrentPageSize(dataSlice.length);

        setDisplayedGridData(dataSlice);
      },
      [
        enablePagination,
        page,
        paginationConfig?.pageSize,
        paginationConfig?.totalPages,
        setCurrentPageSize,
        setDisplayedGridData,
      ],
    );

    // Manage grid data states
    useEffect(() => {
      setGridData(data);
      refreshDisplayData(data);
    }, [data, setGridData, refreshDisplayData]);

    // Manage column states
    useEffect(() => {
      setGridColumns(columns);
    }, [columns, setGridColumns]);

    // Manage grid loading state
    useEffect(() => {
      const isLoading = loadingConfig?.enabled && loadingConfig?.isLoading;
      setIsGridLoading(isLoading || false);
    }, [setIsGridLoading, loadingConfig]);

    // Manage cell popover context
    useEffect(() => {
      setCellPopoverContext(gridPopoverContext);
    }, [gridPopoverContext]);

    // Manage what's displayed on the grid, plus pagination states
    useEffect(() => {
      refreshDisplayData(gridData);
    }, [gridData, refreshDisplayData, lastRefreshTimestamp]);

    // set initial frozenColumn onMount
    useEffect(() => {
      if (configApplied.initialFrozenColumn === false && initialFrozenColumn !== undefined) {
        setFrozenColumn(initialFrozenColumn);
        setConfigApplied({ initialFrozenColumn: true });
      }
    }, [setFrozenColumn, initialFrozenColumn, configApplied]);

    const defaultOnPaste = useCallback(
      (target: Item, values: readonly (readonly string[])[]) => {
        return onPasteWithSingleRowTiling(
          target,
          values,
          selection,
          _getCellContent,
          onCellsEdited,
          onCellsDataPushed,
          dropdownOptionsMapper,
          dropdownConfig,
        );
      },
      [selection, _getCellContent, onCellsEdited, onCellsDataPushed, dropdownOptionsMapper, dropdownConfig],
    );

    const onUpdatePage = (newPage: number) => {
      const updatedPage = Math.max(1, Math.min(numOfPages, newPage));
      setPage(updatedPage);
      return updatedPage;
    };

    const onHeaderMenuClickHandler = (col: number, bounds: Rectangle) => {
      const canClickHeader = canClickHeaderMenu ? canClickHeaderMenu(col, bounds) : true;
      if (canClickHeader && onHeaderMenuClick) {
        onHeaderMenuClick(col, bounds);
      }
      if (!selection.columns.hasIndex(col)) {
        setSelection({ ...selection, columns: CompactSelection.fromSingleSelection(col) });
      }
      setHeaderContextMenuPosition({ col, bounds });
      setContextMenuPlacement('bottom-end');
    };

    const onCellContextMenuHandler = (cell: Item, event: CellClickedEventArgs) => {
      event.preventDefault();

      if (onCellContextMenu) {
        onCellContextMenu(cell, event);
      }

      setContextMenuPlacement('bottom-start');
      setCellContextMenuPosition({ col: cell[0], bounds: event.bounds });
    };

    const handleCloseMenu = () => {
      setHeaderContextMenuPosition(undefined);
      setCellContextMenuPosition(undefined);
    };

    const isHeaderContextMenuOpen = headerContextMenuPosition !== undefined;
    const headerContextMenulayerProps = useGetLayerConfiguration(
      isHeaderContextMenuOpen,
      headerContextMenuPosition,
      contextMenuPlacement,
      setHeaderContextMenuPosition,
    );

    const isCellContextMenuOpen = cellContextMenuPosition !== undefined;
    const cellContextMenulayerProps = useGetLayerConfiguration(
      isCellContextMenuOpen,
      cellContextMenuPosition,
      contextMenuPlacement,
      setCellContextMenuPosition,
    );

    const borderColor = useToken('colors', 'primaryNeutral.400');
    const getBorders = useCallback(
      (borderType: 'vertical' | 'horizontal') => {
        if (borderType === 'horizontal' && !showHorizontalBorders) {
          return undefined;
        }
        if (borderType === 'vertical' && !showVerticalBorders) {
          return undefined;
        }
        return `1px solid ${borderColor}`;
      },
      [borderColor, showHorizontalBorders, showVerticalBorders],
    );

    const isGridLoading = loadingConfig?.enabled && loadingConfig?.isLoading;

    return (
      <>
        <GridMenu config={menuConfig} />
        <Box position="relative">
          {isGridLoading && (
            <Center
              backgroundColor="white"
              bottom={0}
              left={0}
              opacity={0.6}
              position="absolute"
              right={0}
              top={0}
              zIndex={1}
            >
              <Spinner color="primary.600" role="progressbar" size="xl" />
            </Center>
          )}
          <Box borderX={getBorders('vertical')} borderY={getBorders('horizontal')}>
            <DataEditor
              className={className ? ['glide-grid', className].join(' ') : 'glide-grid'}
              coercePasteValue={coercePasteValue}
              columns={gridColumns as any}
              customRenderers={[DropdownCellRenderer, BadgeCellRenderer]}
              drawCell={drawCell}
              freezeColumns={frozenColumn}
              getCellContent={_getCellContent}
              getCellsForSelection={getCellsForSelection}
              getRowThemeOverride={getRowThemeOverride}
              gridSelection={selection}
              headerHeight={headerHeight}
              height={height}
              highlightRegions={highlightRegions}
              initialSize={initialSize}
              keybindings={isGridLoading ? DISABLED_KEY_BINDINGS : { search: true }}
              ref={ref}
              rightElement={rightElement}
              rightElementProps={rightElementProps}
              rowHeight={rowHeight}
              rowMarkers={rowMarkers}
              rowMarkerWidth={30}
              rows={currentPageSize}
              searchResults={menuConfig?.searchConfig?.searchResults}
              searchValue={menuConfig?.searchConfig?.searchValue}
              theme={{
                borderColor,
                horizontalBorderColor: borderColor,
              }}
              trailingRowOptions={trailingRowOptions}
              width={width}
              onCellClicked={(cell, event) =>
                onCellClickHandler(cell, event, setCellPopoverContext, trackCellClickPositions, onCellClicked)
              }
              onCellContextMenu={cellContextMenuItems ? onCellContextMenuHandler : undefined}
              onCellEdited={onCellDataPushed ? (...args) => onCellEdited(...args, onCellDataPushed) : undefined}
              onCellsEdited={onCellsDataPushed ? (...args) => onCellsEdited(...args, onCellsDataPushed) : undefined}
              onColumnMoved={onAfterColumnMove}
              onColumnProposeMove={onBeforeColumnMove}
              onColumnResize={onColumnResize}
              onGridSelectionChange={setSelection as (newSelection: GridSelection) => void}
              onHeaderClicked={onHeaderClicked}
              onHeaderMenuClick={headerContextMenuItems.length > 0 ? onHeaderMenuClickHandler : undefined}
              onPaste={onPaste !== undefined ? onPaste : defaultOnPaste}
              onRowAppended={onRowAppended}
              onVisibleRegionChanged={onVisibleRegionChanged}
            />
          </Box>
        </Box>
        {headerContextMenuConfig && (
          <ContextMenu
            {...headerContextMenulayerProps}
            closeMenu={handleCloseMenu}
            isOpen={isHeaderContextMenuOpen && headerContextMenuItems.length > 0}
            menuItems={headerContextMenuItems}
            ref={headerContextMenulayerProps.ref}
          />
        )}

        {cellContextMenuItems && (
          <ContextMenu
            {...cellContextMenulayerProps}
            closeMenu={handleCloseMenu}
            isOpen={isCellContextMenuOpen && cellContextMenuItems.length > 0}
            menuItems={cellContextMenuItems}
            ref={cellContextMenulayerProps.ref}
          />
        )}

        {gridPopoverConfig && (
          <GridPopover
            closeOnClickOutside={gridPopoverConfig?.closeOnClickOutside}
            enableDrag={gridPopoverConfig.enableDrag}
            isOpen={gridPopoverConfig.isOpen}
            placement={gridPopoverConfig.placement}
            triggerBoundaries={cellPopoverContext?.bounds as IBounds}
            zIndex={gridPopoverConfig.zIndex}
            onClose={gridPopoverConfig.onClose}
          >
            {gridPopoverConfig.getPopoverContent()}
          </GridPopover>
        )}

        {enablePagination && paginationConfig && (
          <Flex alignItems="center" direction="row" justifyContent="center" marginBottom="1em" marginTop="1em">
            <Button
              unmask
              onClick={() => {
                const updatedPage = onUpdatePage(page - 1);
                if (paginationConfig.onPreviousPage) {
                  paginationConfig.onPreviousPage(updatedPage);
                } else {
                  onUpdatePage(updatedPage);
                }
              }}
            >
              {paginationConfig.paginationPrevLabel}
            </Button>
            <Text margin="0 20px">{page}</Text>
            <Button
              unmask
              onClick={() => {
                const updatedPage = onUpdatePage(page + 1);
                if (paginationConfig.onNextPage) {
                  paginationConfig.onNextPage(updatedPage);
                } else {
                  onUpdatePage(updatedPage);
                }
              }}
            >
              {paginationConfig.paginationNextLabel}
            </Button>
          </Flex>
        )}
        {
          // The following is needed to make the editor overlay work in the grid
        }
        <div
          data-testid="portal"
          id="portal"
          style={{
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 9999,
          }}
        />
      </>
    );
  },
);
