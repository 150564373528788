import { EditableGridCell, Item } from '@glideapps/glide-data-grid';

export enum GridChangeEventType {
  CELL_EDITED = 'CELL_EDITED',
}

export type GridChangeEventBase = {
  id: string;
  created_at: number; // UTC timestamp
  type: GridChangeEventType;
};

export type GridCellEditEvent = GridChangeEventBase & {
  cell: Item;
  newCellValue: EditableGridCell;
  type: GridChangeEventType.CELL_EDITED;
};

export type GridChangeEvent = GridCellEditEvent;
